import React from 'react';
import ReactDOM from 'react-dom';

import { I18nextProvider } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18next from 'i18next';
import global_es_Co from './i18n/esCO/es-CO.json';
import global_es_Mx from './i18n/esMX/es-MX.json';

import App from './App';
import './index.css';

i18next.use(LanguageDetector)
  .init({
    interpolation: { escapeValue: false },
    lng: 'esCO',
    resources: {
      esCO: {
        global: global_es_Co,
      },
      esMX: {
        global: global_es_Mx,
      },
    },
  });

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
