import React, { Suspense } from 'react';
import 'App.css';
import 'theme/fonts.css';
import 'theme/colors.css';

import LoadingMobile from 'components/LoaderMobile';
import Routes from 'routes/index.jsx';

function App() {
  return (
    <div className='general-container'>
      <Suspense fallback={<LoadingMobile />}>
        <Routes />
      </Suspense>
    </div>
  );
}

export default App;
