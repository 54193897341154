import React from 'react';

import NoStoresImage from 'assets/NoStores.png';

import styles from './styles/NoStores.module.css';

export default function NoStore() {
  return (
    <main className={styles.container}>
      <img src={NoStoresImage} alt='No hay tiendas asociadas a este link.' />
      <div>No hay tiendas asociadas a este link.</div>
    </main>
  );
}
