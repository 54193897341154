import axios from 'axios';

const URL_API = process.env.REACT_APP_URL_API;

const getHeaders = () => {
  const headers = {};
  // if (isAuth()) {
  // headers = { headers: { Authorization: `Bearer ${getToken()}` } };
  // }
  return headers;
};

export const get = async (path) => {
  const headers = getHeaders();
  let resolveReturn;
  let rejectReturn;
  const promise = new Promise((resolve, reject) => {
    resolveReturn = resolve;
    rejectReturn = reject;
  });
  try {
    const resp = axios.get(`${URL_API}${path}`, headers);
    resolveReturn(resp);
  } catch (error) {
    rejectReturn(error);
  }
  return promise;
};

export const post = (path, params) => {
  const headers = getHeaders();
  return axios.post(`${URL_API}${path}`, params, headers);
};
