import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import NoStores from 'modules/NoStores/index.jsx';
import AppContext from 'context/AppContext.jsx';
import useInitialState from 'hooks/useInitialState.jsx';

const Cart = React.lazy(() => import('../modules/Cart/index.jsx'));
const Categories = React.lazy(() => import('../modules/Categories/index.jsx'));
const Dashboard = React.lazy(() => import('../modules/Dashboard/index.jsx'));
const Discounts = React.lazy(() => import('../modules/Discounts/index.jsx'));
const ListOfCategories = React.lazy(() => import('../modules/ListOfCategories/index.jsx'));
const MostSold = React.lazy(() => import('../modules/MostSold/index.jsx'));
const Promotions = React.lazy(() => import('../modules/Promotions/index.jsx'));
const Product = React.lazy(() => import('../modules/Products/index.jsx'));
const Search = React.lazy(() => import('../modules/Search/index.jsx'));

const Routes = () => {
  const initialState = useInitialState();
  return (
    <Router>
      <AppContext.Provider value={initialState}>
        <Switch>
          <Route exact path='/' component={NoStores} />
          <Route exact path='/:storeId' component={Dashboard} />
          <Route path='/:storeId/home' component={Dashboard} />
          <Route path='/:storeId/cart' component={Cart} />
          <Route path='/:storeId/categories' component={Categories} />
          <Route path='/:storeId/category/list' component={ListOfCategories} />
          <Route path='/:storeId/discounts' component={Discounts} />
          <Route path='/:storeId/most-sold' component={MostSold} />
          <Route path='/:storeId/promotions' component={Promotions} />
          <Route path='/:storeId/products' component={Product} />
          <Route path='/:storeId/search' component={Search} />
          <Redirect from='/*' to='/' />
        </Switch>
      </AppContext.Provider>
    </Router>
  );
};

export default React.memo(Routes);
