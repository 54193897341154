import { useEffect, useState } from 'react';
import { get } from 'utils/axios';

const useInitialState = () => {
  const { pathname } = window.location;
  const storeId = pathname.split('/');
  const [categories, setCategories] = useState(false);
  const [categoryIds, setCategoryIds] = useState([]);
  const [cityId, setCityID] = useState(1);
  const [cityName, setCityName] = useState('');
  const [errorNetwork, setErrorNetwork] = useState(false);
  const [errorGeneral, setErrorGeneral] = useState(false);
  const [loadingDash, setLoadingDash] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [store, setStore] = useState({});
  const [storeAddress, setStoreAddress] = useState('');
  const [storeName, setStoreName] = useState('');
  const [storePhone, setStorePhone] = useState('');

  const [loadingCategories, setLoadingCategories] = useState(false);
  const [filterByCategory, setFilterByCategory] = useState([]);

  const getDataHomePage = () => {
    setErrorGeneral(false);
    setErrorNetwork(false);
    setLoadingDash(true);
    get(`dash/homepage/${storeId[1]}`)
      .then((resp) => {
        setLoadingDash(false);
        setCategories(resp.data.categories);
        setCategoryIds(resp.data.categories.map((category) => category.categoryId));
        setCityID(resp.data.store.cityId);
        setPhoneNumber(resp.data.store.internationalPhone);
        setStore(resp.data.store);
        setStorePhone(resp.data.store.phone);
        setStoreAddress(resp.data.store.address);
        setStoreName(resp.data.store.storeName);
        setCityName(resp.data.store.cityName);
      }).catch((err) => {
        setLoadingDash(false);
        if (err.message === 'Network Error') setErrorNetwork(true);
        else setErrorGeneral(true);
      });
  };

  useEffect(() => {
    getDataHomePage();
  }, [pathname]);

  const getProductsByCategories = (categoryId) => {
    if (categoryId) {
      setErrorGeneral(false);
      setErrorNetwork(false);
      setLoadingCategories(true);
      get(`reference/filter-by-category/${storeId[1]}/${categoryId}?limit=5&skip=0`)
        .then((resp) => {
          setLoadingCategories(false);
          if (resp.data.quantityTotal > 0) {
            setFilterByCategory([...filterByCategory, { categoryId, data: resp.data }]);
          }
        })
        .catch((err) => {
          setLoadingCategories(false);
          if (err.message === 'Network Error') setErrorNetwork(true);
          else setErrorGeneral(true);
        });
    }
  };

  return {
    categories,
    categoryIds,
    cityId,
    cityName,
    errorGeneral,
    errorNetwork,
    filterByCategory,
    getProductsByCategories,
    loadingCategories,
    loadingDash,
    phoneNumber,
    store,
    storeAddress,
    storeName,
    storePhone,
  };
};

export default useInitialState;
