import React from 'react';
import styles from './loaderMobile.module.css';

const LoaderMobile = () => (
  <div className={styles.loader}>
    <div className={styles.bg}>{' '}</div>
    <div className={styles.circle}>{' '}</div>
    <div className={styles.circle}>{' '}</div>
    <div className={styles.circle}>{' '}</div>
    <div className={styles.circle}>{' '}</div>
    <div className={styles.circle}>{' '}</div>
  </div>
);

export default LoaderMobile;
